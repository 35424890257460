import React from 'react';
import styled from 'styled-components';

import MaxWidthWrapper from '@components/MaxWidthWrapper';
import Footer from '@components/Footer';
import Spacer from '@components/Spacer';
import SEO from '@components/SEO';

export default function ConfirmedPage() {
  return (
    <Wrapper>
      <MaxWidthWrapper>
        <Title>Confirmed!</Title>
        <p>
          Thanks <em>so much</em> for coming with me on this journey.
          Welcome aboard!
        </p>
        <p>
          We're getting pretty close to the release now — I'll be in
          touch before Sept 27th with all of the pertinent info.{' '}
          <span role="img" aria-label="grinning face">
            😄
          </span>
        </p>

        <p>
          I recently published a blog post,{' '}
          <a href="https://www.joshwcomeau.com/css/the-importance-of-learning-css/">
            “The Importance of Learning CSS”
          </a>
          . Near the end, I include a list of free resources that you
          can use to start strengthening your knowledge of CSS right
          away!
        </p>

        <Spacer $size={64} />
      </MaxWidthWrapper>
      <Footer />
      <SEO />
    </Wrapper>
  );
}

const Wrapper = styled.main`
  padding-top: min(250px, 10vh);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    font-size: 1.25rem;
  }
`;

const Title = styled.h1`
  font-size: 2em;
  font-weight: 500;
  margin-bottom: 3rem;
`;
